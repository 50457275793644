.controls {
  display: flex;
  gap: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
