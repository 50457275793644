.wrapper {
  padding: 20px;
  row-gap: 20px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  min-height: 381px;
  box-shadow: 4px 16px 50px 0px #0000000d;
  justify-content: space-between;
}

.iconWrapper {
  width: 85px;
  height: 85px;
  padding: 14px;
  gap: 4px;
  border-radius: 12px;
  margin: 0 auto 36px;
}
